import React, { useState } from 'react';
import { ethers } from 'ethers';

function ClaimButton({ contractAddress, contractABI }) {
  const [status, setStatus] = useState('');

  async function handleClaim() {
    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const contract = new ethers.Contract(contractAddress, contractABI, signer);
  
      setStatus('Processing...');
  
      const claimQty = 1; // You can adjust this quantity as needed
      const claimTransaction = await contract.claimAllocation(claimQty, {
        value: 0, // Set the value to 0 as the ALLOW_LIST_PRICE is set to 0
      });
  
      await claimTransaction.wait();
  
      setStatus('Claim successful! Transaction hash: ' + claimTransaction.hash);
    } catch (error) {
      const errorMessage = error.data?.originalError?.message || error.message;
      const executionRevertedIndex = errorMessage.indexOf("execution reverted:");
      if (executionRevertedIndex !== -1) {
        const commaIndex = errorMessage.indexOf(",", executionRevertedIndex);
        const daoErrorMessage = commaIndex !== -1 ? errorMessage.substring(executionRevertedIndex, commaIndex) : errorMessage.substring(executionRevertedIndex);
        setStatus('Execution Reverted: ' + daoErrorMessage.trim());
      } else {
        setStatus(errorMessage);
      }
    }
  }
  
  return (
    <div>
      <button className='claim-pass-button' onClick={handleClaim}>Claim Pass</button>
      <p>{status}</p>
    </div>
  );
}

export default ClaimButton;