import React from 'react';
import logo from '../assets/bear-music_room_dapp.png';

const Footer = () => (
  <footer style={{backgroundColor: '#002CEF'}} className="w-full fixed bottom-0">
    <div className="flex justify-end p-4">
      <img src={logo} alt='Logo' style={{marginBottom: '18px', marginRight: '18px', width: '111px', height: '96px', flexShrink: '0', objectFit: 'contain'}} className='h-40 w-auto' />
    </div>
  </footer>
);

export default Footer;