import './Nav.css'; 

const Nav = () => (
  <nav className="nav">
    <div className="title">
      <span>Music Room</span>
    </div>
    <div className="links">
      <div className="link">
        <span>i. </span>
        <a href='https://inbetweeners.notion.site/MUSIC-ROOM-WHITE-PAPER-84691939ade24d8ab161e3f771ea6435' target='_blank' rel='noopener noreferrer'>White Paper</a>
      </div>
      <div className="link">
        <span>ii. </span>
        <a href='https://share.hsforms.com/1oo_4yUfeR46SL0Ovzv1-2wq1czl' target='_blank' rel='noopener noreferrer'>Join Waitlist</a>
      </div>
    </div>
  </nav>
);


export default Nav;
