import React from 'react';
import { DynamicContextProvider } from "@dynamic-labs/sdk-react";
import Home from "./components/Home";
import Menu from './components/Nav'; 
import Footer from "./components/Footer"

function App() {

  return (
    <div className="App">
      <DynamicContextProvider
        settings={{
          initialAuthenticationMode: 'connect-only',
          environmentId: process.env.REACT_APP_DYNAMIC_PROJECT_ID || "",
        }}
      >
        <Menu />
        <Home />
        <Footer />
      </DynamicContextProvider>
    </div>
  );
}

export default App;
