import React from 'react';
import { DynamicWidget } from '@dynamic-labs/sdk-react';
import ClaimButton from './ClaimButton';
import Nav from "./Nav";

const contractAddress = process.env.REACT_APP_CONTRACT;
const contractABI = process.env.REACT_APP_ABI;

const Home = () => (
  <div style={{backgroundColor: '#002CEF', overflow: 'hidden'}} className="min-h-screen relative flex align-center justify-end flex-col bg-home bg-contain bg-no-repeat bg-center home">
    <Nav />

    <div className="absolute inset-0 flex items-center justify-center">
      <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh'}}>
           <DynamicWidget
            innerButtonComponent={<button style={{ flexShrink: '0', color: '#000', textAlign: 'center', fontFamily: 'Rubik', fontSize: '26px', fontStyle: 'normal', fontWeight: '400', lineHeight: 'normal'}}>Connect Wallet</button>}>
           </DynamicWidget>
           <button className="bg-blue-500 text-white font-bold py-2 px-4 rounded mt-2">
          <ClaimButton contractAddress={contractAddress} contractABI={contractABI} />
        </button>
      </div>
    </div>
  </div>
);

export default Home;